import React from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1cbeda",
    color: "white",
    maxWidth: "140px",
    boxShadow: theme.shadows[1],
    fontFamily: "OpenSans",
    fontWeight: "bold",
    fontSize: 11,
    animation: "fadeInAnimation ease 0.5s",
  },
}));

const TooltipComponent = (props: any) => {
  if (!props.show) {
    return <>{props.children}</>;
  }

  return (
    <CustomTooltip
      title={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div style={{ textAlign: "center", padding: "2px" }}>
            {props.text}
          </div>
        </div>
      }
      placement={"left"}
    >
      {props.children}
    </CustomTooltip>
  );
};

export default TooltipComponent;
