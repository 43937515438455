import React from "react";
import { createTheme, styled, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import styles from "./SigningWizard.module.css";
import { StepIconProps } from "@mui/material/StepIcon";
import Check from "@mui/icons-material/Check";

const steps = [
  { label: "Standard" },
  { label: "Provider" },
  { label: "Legal weight" },
  { label: "Phone" },
];

const QontoStepIconRoot = styled("div")<{ ownerState: { active?: boolean } }>(
  ({ theme, ownerState }) => ({
    color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
    ...(ownerState.active && {
      color: "#784af4",
    }),
    "& .QontoStepIcon-completedIcon": {
      color: "#784af4",
      zIndex: 1,
      fontSize: 24,
      marginLeft: "-1px",
    },
    "& .QontoStepIcon-circle": {
      width: 15,
      height: 15,
      borderRadius: "50%",
      backgroundColor: "currentColor",
      marginLeft: "5px",
    },
  })
);

function QontoStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;
  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

const theme = createTheme({
  components: {
    // Name of the component
    MuiStepLabel: {
      styleOverrides: {
        label: {
          fontSize: 11,
        },
      },
    },
  },
});

const StepperTypeComponent = ({ currentPage, stepBackButtonHandler }: any) => {
  if (window.innerWidth < 1451) {
    return (
      <ThemeProvider theme={theme}>
        <Box sx={{ width: "100%" }}>
          <Stepper
            activeStep={currentPage}
            alternativeLabel
            style={{ justifyContent: "center" }}
          >
            {steps.map((step, index) => (
              <Step
                key={step.label}
                onClick={() => {
                  stepBackButtonHandler(index);
                }}
              >
                <StepLabel>{step.label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
      </ThemeProvider>
    );
  }

  return (
    <Box
      sx={{
        width: "31%",
        borderRight: "1px solid #623d91",
        minHeight: "314px",
        display: "flex",
      }}
    >
      <Stepper activeStep={currentPage} orientation="vertical">
        {steps.map((step, index) => (
          <Step
            key={step.label}
            className={styles.SignatorStepperType}
            onClick={() => {
              stepBackButtonHandler(index);
            }}
          >
            <StepLabel
              StepIconComponent={QontoStepIcon}
              className={styles.SignatorStepperLabel}
            >
              {step.label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default StepperTypeComponent;
