import axios from "axios";
import { SignatureResponseData, SignatureStatus } from "./SigningComponent";
import { ModalDialog } from "./ModalDialog";
import {
  SigningStatusDialog,
  SigningStatusComponent,
} from "./SigningStatusComponent";
import { InitialDialog } from "./InitializeSigningComponent";
import i18next from "i18next";

export class ProcessSigning {
  private readonly signatureResponseUrl: string;
  private readonly signatureTimer: any;
  private readonly localComponentCheck?: boolean | undefined;
  private readonly signatureJurisdiction?: string | undefined;
  private readonly signatureLevel?: string | undefined;
  signatureToken?: string | undefined;
  mobileNumber?: string | undefined;
  device?: string | undefined;
  language?: string | undefined;

  constructor(
    signatureResponseUrl: string,
    signatureTimer: any,
    localComponentCheck?: boolean | undefined,
    signatureJurisdiction?: string | undefined,
    signatureLevel?: string | undefined,
    signatureToken?: string | undefined,
    mobileNumber?: string | undefined,
    device?: string | undefined,
    language?: string | undefined
  ) {
    this.signatureResponseUrl = signatureResponseUrl;
    this.signatureTimer = signatureTimer;
    this.localComponentCheck = localComponentCheck;
    this.signatureJurisdiction = signatureJurisdiction;
    this.signatureLevel = signatureLevel;
    this.signatureToken = signatureToken;
    this.mobileNumber = mobileNumber;
    this.device = device;
    this.language = language;
  }

  public processSigning() {
    const clearIntervalParam = () => {
      clearInterval(this.signatureTimer);
    };

    function listenForCryptoResult(event: any) {
      if (event.data.type === "SIGNATOR_SIGNING_ERROR_CRYPTO_CONNECTION") {
        window.postMessage({
          type: "SIGNATOR_SIGNING_ERROR_LOCAL_COMPONENT",
          error: "SIGNATOR_SIGNING_ERROR_LOCAL_COMPONENT",
        });

        clearIntervalParam();

        window.removeEventListener('message', listenForCryptoResult)
      }
    }

    axios.get(this.signatureResponseUrl ?? "").then(async (response) => {
      let signatureResponse = response.data as SignatureResponseData;
      let responseUrlDialogInstance = new ModalDialog(
        response.data.OperationData?.Url + `?lang=${this.language}`,
        response.data.SignatureProvider,
        this.signatureJurisdiction,
        this.signatureLevel,
        clearIntervalParam
      );
      let signingModalDialog = document.getElementById("signing-modal");
      let statusDialogInstance = new SigningStatusComponent();
      let statusModalDialog = document.getElementById("status-modal");
      let initialDialogInstance = new InitialDialog();
      let initialDialog = document.getElementById("initial-modal");

      switch (signatureResponse.Status) {
        case SignatureStatus.INITIALIZED:
          if (!initialDialog) {
            initialDialogInstance.renderInitialModal(clearIntervalParam);
          }
          break;
        case SignatureStatus.WAITINGFORUSERAPPROVAL:
          if (
            this.localComponentCheck !== undefined &&
            this.localComponentCheck === false
          ) {
            if (response.data.OperationData?.Url) {
              if (initialDialog) {
                initialDialogInstance.removeInitialModalFromDom();
              }
              if (!signingModalDialog) {
                responseUrlDialogInstance.renderModal();
                window.addEventListener("message", listenForCryptoResult, false);
              }
            } else {
              window.postMessage({
                type: "SIGNATOR_SIGNING_ERROR_LOCAL_COMPONENT",
                error: "SIGNATOR_SIGNING_ERROR_LOCAL_COMPONENT",
              });

              clearInterval(this.signatureTimer);
            }
          } else {
            if (initialDialog) {
              initialDialogInstance.removeInitialModalFromDom();
            }
            if (!signingModalDialog) {
              responseUrlDialogInstance.renderModal();
            }
          }

          break;
        case SignatureStatus.DELIVERED:
          if (initialDialog) {
            initialDialogInstance.removeInitialModalFromDom();
          }
          if (signingModalDialog) {
            responseUrlDialogInstance.removeSigningModalFromDom();
          }
          if (!statusModalDialog) {
            statusDialogInstance.renderStatusModal(
              SigningStatusDialog.SUCCESS,
              undefined,
              response.data.OperationData.SignedDocument
            );
          }
          clearInterval(this.signatureTimer);
          break;
        case SignatureStatus.FAILED:
          if (
            response.data.OperationData.ErrorCode &&
            response.data.OperationData.ErrorCode === 20010
          ) {
            window.parent.postMessage({
              type: "SIGNATOR_SIGNING_ERROR_VIDEO_IDENT",
              error: "NO_DIGITAL_IDENT",
            });

            clearInterval(this.signatureTimer);
            break;
          }

          if (
            response.data.OperationData.ErrorCode &&
            response.data.OperationData.ErrorCode === 20001
          ) {
            window.parent.postMessage({
              type: "SIGNATOR_SIGNING_ERROR_VIDEO_IDENT",
              error: "NO_DIGITAL_IDENT",
            });

            clearInterval(this.signatureTimer);
            break;
          }

          if (
            response.data.OperationData.ErrorCode &&
            response.data.OperationData.ErrorCode === 25027
          ) {
            window.parent.postMessage({
              type: "SIGNATOR_NO_CREDITS",
              error: "NO_CREDITS_ERROR",
            });

            clearInterval(this.signatureTimer);
            break;
          }

          if (
            response.data.OperationData.ErrorCode &&
            response.data.OperationData.ErrorCode === 25028
          ) {
            window.parent.postMessage({
              type: "SIGNATOR_NO_CREDITS",
              error: "NO_CREDITS_ERROR",
            });

            clearInterval(this.signatureTimer);
            break;
          }

          if (
            response.data.OperationData.ErrorCode &&
            response.data.OperationData.ErrorCode === 25003
          ) {
            window.parent.postMessage({
              type: "SIGNATOR_NO_CREDITS",
              error: "NO_CREDITS_ERROR",
            });

            clearInterval(this.signatureTimer);
            break;
          }

          if (initialDialog) {
            initialDialogInstance.removeInitialModalFromDom();
          }
          if (signingModalDialog) {
            responseUrlDialogInstance.removeSigningModalFromDom();
          }
          if (!statusModalDialog) {
            if (signatureResponse.OperationData.ErrorCode)
              statusDialogInstance.renderStatusModal(
                SigningStatusDialog.FAILED,
                signatureResponse.OperationData.ErrorCode
              );
          }
          clearInterval(this.signatureTimer);
          break;
        case SignatureStatus.UNKNOWN:
          if (initialDialog) {
            initialDialogInstance.removeInitialModalFromDom();
          }
          if (signingModalDialog) {
            responseUrlDialogInstance.removeSigningModalFromDom();
          }
          if (!statusModalDialog) {
            if (signatureResponse.OperationData.ErrorCode)
              statusDialogInstance.renderStatusModal(
                SigningStatusDialog.FAILED,
                undefined
              );
          }
          clearInterval(this.signatureTimer);
          break;

        default:
          if (initialDialog) {
            initialDialogInstance.removeInitialModalFromDom();
          }
          if (signingModalDialog) {
            responseUrlDialogInstance.removeSigningModalFromDom();
          }
          if (!statusModalDialog) {
            if (signatureResponse.OperationData.ErrorCode)
              statusDialogInstance.renderStatusModal(
                SigningStatusDialog.FAILED,
                undefined
              );
          }

          clearInterval(this.signatureTimer);
          break;
      }
    });
  }
}
