import { SignatureProvider } from "./SigningComponent";

export class ModalDialog {
  private readonly dialogUrl: string | undefined;
  private readonly signatureProvider: string | undefined;
  private readonly signatureLevel: string | undefined;
  private readonly signatureJurisdiction: string | undefined;
  private readonly clearInterval: Function | undefined;

  constructor(
    dialogUrl: string,
    signatureProvider?: string,
    signatureLevel?: string,
    signatureJurisdiction?: string,
    clearInterval?: Function
  ) {
    this.dialogUrl = dialogUrl;
    this.signatureProvider = signatureProvider;
    this.signatureLevel = signatureLevel;
    this.signatureJurisdiction = signatureJurisdiction;
    this.clearInterval = clearInterval;
  }

  public removeSigningModalFromDom() {
    if (this.clearInterval) {
      this.clearInterval();
    }
    let signingModal = document.getElementById("signing-modal");
    if (signingModal) {
      if (
        this.signatureProvider === SignatureProvider.CertiliaRdd &&
        this.clearInterval
      ) {
        this.clearInterval();
      }
      if (
        this.signatureProvider === SignatureProvider.Certilia &&
        this.clearInterval
      ) {
        this.clearInterval();
      }
      signingModal.remove();
    }
  }

  //create and render modal which contains iframe
  public renderModal() {
    //if drawer is closed, clear interval
    const listenAndCloseInterval = (event: any) => {
      if (event.data.status === "SIGNATOR_DRAWER_CLOSED") {
        this.removeSigningModalFromDom();
        if (this.clearInterval) this.clearInterval();
      }
    };

    window.addEventListener("message", listenAndCloseInterval, false);

    //create stylesheet
    let styleSheet = document.createElement("style");

    if (this.signatureProvider === SignatureProvider.CertiliaRdd) {
      styleSheet.innerHTML = `   
    .signing-modal-content-dialog {
      position: relative;
      background-color: #fefefe;
      margin: auto;
      padding: 0;
      max-width: 100%;
      box-shadow: none !important;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    .iframe-modal-confirmation{
      height: 900px;
      width: 99%;
    }
    `;
    } else if (this.signatureProvider === SignatureProvider.Certilia) {
      styleSheet.innerHTML = `
    .signing-modal-content-dialog {
      position: relative;
      background-color: #fefefe;
      margin: auto;
      padding: 0;
      max-width: 600px;
      box-shadow: none !important;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    .iframe-modal-confirmation{
      height: 650px;
      width: 99%;
    }
    `;
    } else {
      styleSheet.innerHTML = `
      .signing-modal-content-dialog {
        position: relative;
        background-color: #fefefe;
        margin: auto;
        padding: 0;
        max-width: 800px;
        width: 100%;
        box-shadow: none !important;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }
  
      .iframe-modal-confirmation{
        height: 484px;
        width: 99%;
      }

      .signing-modal-sig-data {
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        font-family: 'OpenSans';
        color: #6e4d99;
        font-weight: bold;
        font-size: smaller;
      }

      .signing-modal-sig-data-text{
        margin-bottom: 4px;
        padding-left: 30px;
        padding-right: 30px;
      }

      .signing-modal-sig-data-text-bottom{
        margin-bottom: 20px;
        padding-left: 30px;
        padding-right: 30px;
      }
      `;
    }

    if (this.dialogUrl !== undefined) {
      //append CSS for modal
      document.head.appendChild(styleSheet);

      //create the background element for modal
      const modal = document.createElement("div");
      modal.id = "signing-modal";

      //create modal content div element
      const modalContent = document.createElement("div");
      modalContent.classList.add("signing-modal-content-dialog");

      //create the inner iframe element
      const iframe = document.createElement("iframe");
      iframe.id = "iframe-modal-confirmation";
      iframe.classList.add("iframe-modal-confirmation");
      iframe.sandbox.add("allow-forms");
      iframe.sandbox.add("allow-same-origin");
      iframe.sandbox.add("allow-scripts");
      iframe.sandbox.add("allow-top-navigation");
      iframe.src = this.dialogUrl;

      let parentElementToAppend = document.getElementById(
        "signator_drawer_container"
      );

      //render the modal element with child iframe on DOM
      modal.appendChild(modalContent);
      modalContent.appendChild(iframe);
      if(parentElementToAppend){
        parentElementToAppend.appendChild(modal);
      }
    } else {
      return;
    }
  }
}
