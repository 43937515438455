import styles from "./ProfilesSidebar.module.css";
import React, { useState } from "react";
import { SignatureProfileSettings } from "../SignatureProfileComponent/SignatureProfileSettings";
import i18next from "i18next";

interface IProfileCard {
  profile: any;
  onDragStart: any;
  addSignature: boolean;
  createSignatureImageOnClick: (profile: any) => void;
}
export const ProfileCard = (props: IProfileCard) => {
  const [dragStarted, setDragStarted] = useState(false);

  return (
    <div
      style={{ width: "100%", opacity: props.addSignature ? "40%" : "100%" }}
      className={
        !dragStarted
          ? "signatureProfileCardWrapper"
          : "signatureProfileCardWrapperDragging"
      }
    >
      <div className={"signatureProfileCardInner"}>
        <div className={"signatureProfileFrontCard"}>
          <div
            className={`signatureProfileCard${
              props.profile.txtSignatureLevel ?? "Local"
            }`}
            style={{
              backgroundColor: "white",
              marginBottom: "0px",
            }}
            draggable={true}
            onClick={() => props.createSignatureImageOnClick(props.profile)}
            onDragStart={(e) => props.onDragStart(e, props.profile)}
          >
            <div className={styles.signatureProfileCardTop}>
              <img
                src={
                  "data:image/png;base64, " +
                  props.profile.txtSignatureImagePicture
                }
                width={75}
                height={37}
                alt={"Signature image"}
                className={styles.signatureImageInProfile}
              />
              <div className={styles.signatureProfileBasicDetails}>
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "10px",
                  }}
                >
                  {props.profile.txtName}
                </span>
                {props.profile.txtSignatureProvider !==
                  "ExternalLocalComponent" && (
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "10px",
                    }}
                  >
                    {props.profile.txtMobitel}
                  </span>
                )}
              </div>
            </div>
            <SignatureProfileSettings
              profile={props.profile}
              smallFont={true}
            />
          </div>
        </div>
        <div
          className={"signatureProfileBackCard"}
          draggable={true}
          onDragStart={(e) => {
            setDragStarted(true);
            props.onDragStart(e, props.profile);
          }}
        >
          {i18next.t("drag_and_drop_image")}
        </div>
      </div>
    </div>
  );
};
