export const signatureFrameReducer = (state: any, action: any) => {
    if (action.type === "ON_ZOOM") {
        let scaleDiff = action.scale / state.scale;
        return {
            size: [state.size[0] * scaleDiff, state.size[1] * scaleDiff],
            translate: [
                state.translate[0] * scaleDiff,
                state.translate[1] * scaleDiff,
            ],
            rotate: 0,
            transformOrigin: "50% 50%",
            scale: action.scale,
            opacity: action.opacity,
            signaturePage: state.signaturePage,
        };
    }
    if (action.type === "ON_LOAD_TRANSLATE") {
        return {
            size: state.size,
            translate: action.translate,
            rotate: 0,
            transformOrigin: "50% 50%",
            scale: state.scale,
            opacity: state.opacity,
            signaturePage: state.signaturePage,
        };
    }
    if (action.type === "ON_RESIZE") {
        let scaleDiff = action.scale / state.scale;
        return {
            size: [action.size[0] * scaleDiff, action.size[1] * scaleDiff],
            translate: action.translate,
            rotate: 0,
            transformOrigin: "50% 50%",
            scale: action.scale,
            opacity: state.opacity,
            signaturePage: state.signaturePage,
        };
    }
    if (action.type === "ON_DRAG") {
        return {
            size: state.size,
            translate: action.translate,
            rotate: 0,
            transformOrigin: "50% 50%",
            scale: state.scale,
            opacity: state.opacity,
            signaturePage: state.signaturePage,
        };
    }
    if (action.type === "ON_PAGE_CHANGE_UP") {
        document.body.style.cursor = "wait";
        setTimeout(() => {
            let signatureImageElement = document.getElementById("signature-field");
            if (signatureImageElement) {
                signatureImageElement.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                });
            }
        }, 500);
        return {
            size: action.size,
            translate: action.translate,
            rotate: 0,
            transformOrigin: "50% 50%",
            scale: state.scale,
            opacity: action.opacity,
            signaturePage: action.signaturePage,
        };
    }
    if (action.type === "ON_PAGE_CHANGE_DOWN") {
        document.body.style.cursor = "wait";
        setTimeout(() => {
            let signatureImageElement = document.getElementById("signature-field");
            if (signatureImageElement) {
                signatureImageElement.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                });
            }
        }, 500);
        return {
            size: action.size,
            translate: action.translate,
            rotate: 0,
            transformOrigin: "50% 50%",
            scale: state.scale,
            opacity: action.opacity,
            signaturePage: action.signaturePage,
        };
    }
    if (action.type === "ON_REMOVE") {
        return {
            size: [150 * state.scale, 75 * state.scale],
            translate: [100 * state.scale, 100 * state.scale],
            rotate: 0,
            transformOrigin: "50% 50%",
            scale: state.scale,
            opacity: state.opacity,
            signaturePage: state.signaturePage,
        };
    }
    if (action.type === "ON_CHANGE_OPACITY") {
        return {
            size: state.size,
            translate: state.translate,
            rotate: 0,
            transformOrigin: "50% 50%",
            scale: state.scale,
            opacity: action.opacity,
            signaturePage: state.signaturePage,
        };
    }
    if (action.type === "ON_PAGE_CHANGE") {
        return {
            size: state.size,
            translate: state.translate,
            rotate: 0,
            transformOrigin: "50% 50%",
            scale: state.scale,
            opacity: state.opacity,
            signaturePage: action.signaturePage,
        };
    }
    return {
        size: [150, 75],
        translate: [226, 373],
        rotate: 0,
        transformOrigin: "50% 50%",
        scale: 1,
        opacity: "100%",
        signaturePage: 1,
    };
};