import React, { useRef, useState, useContext } from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import styles from "./SignButton.module.css";
import IntroTooltip from "../IntroTooltip/IntroTooltip";
import IntroTooltipContext from "../../store/IntroTooltipContext";
import i18next from "i18next";
import SignatureParametersContext from "../../store/SignatureParametersContext";
import {ErrorModalService} from "../ErrorHandling/ErrorModalService";

const options = ["Sign"];

const SignButtonMenu = (props: any) => {
  const tooltipCtx = useContext(IntroTooltipContext);
  const signatureParamsCtx = useContext(SignatureParametersContext);

  //state variables

  const [openSignButtonDropdown, setOpenSignButtonDropdown] = useState({
    open: false,
    selectedIndex: 0,
  });

  //refs
  const signButtonDropdownAnchorRef = useRef<HTMLDivElement>(null);

  //handlers
  const handleClick = () => {
    if (props.isMobile) {
      if (props.isSigAdded) {
        props.signWithImage();
      } else {
        props.signWithoutImageModalHandler();
      }
    } else {
      if(signatureParamsCtx.signatureType === "image" && !props.isSigAdded){
        new ErrorModalService(35).showErrorModal();
        return;
      } else if(signatureParamsCtx.signatureType === "noimage" && !props.profileButtonChecked){
        new ErrorModalService(34).showErrorModal();
        return;
      } else if (signatureParamsCtx.signatureType === "image" && props.isSigAdded) {
        props.signWithImage();
      } else if (
        signatureParamsCtx.signatureType === "image" &&
        !props.isSigAdded
      ) {
        props.signWithImageHandler();
      } else {
        props.directSignHandler();
      }
    }
  };

  const handleHideTooltipButton = () => {
    tooltipCtx.setTooltipStep(tooltipCtx.currentTooltipStep! + 1);
  };

  return (
    <IntroTooltip
      tooltipTextIndex={5}
      open={
        tooltipCtx.showTooltips === true && tooltipCtx.currentTooltipStep === 5
      }
      placement={"top"}
      hideTooltip={handleHideTooltipButton}
    >
      <ButtonGroup
        variant="contained"
        ref={signButtonDropdownAnchorRef}
        aria-label="split button"
        style={{ backgroundColor: "transparent", boxShadow: "none" }}
      >
        <Button
          className={
            props.isSigAdded
              ? styles.Signator_SignButtonAnimation
              : styles.Signator_SignButtonNoAnimation
          }
          onClick={handleClick}
          style={{
            backgroundColor: "#1cbeda",
            borderColor: "white",
            color: "white",
            fontSize: "13px",
            marginLeft: "20px",
            borderTopLeftRadius: "1.30rem",
            borderBottomLeftRadius: "1.30rem",
            borderTopRightRadius: "1.30rem",
            borderBottomRightRadius: "1.30rem",
            height: "40px",
            minWidth: props.isMobile ? "70px" : "100px",
            fontWeight: "bold",
            fontFamily: "OpenSans",
            textTransform: "none",
          }}
        >
          {i18next.t("sign_label")}
        </Button>
      </ButtonGroup>
    </IntroTooltip>
  );
};

export default SignButtonMenu;
