import React, { useContext, useEffect, useState } from "react";

import styles from "./LandingPage.module.css";
import Vizibit from "../images/vizibit.png";
import PoweredByLogo from "../images/PVB_3.png";
import DropZone from "../components/DropZone/DropZone";
import { useNavigate } from "react-router-dom";
import i18next from "i18next";
import { Spin } from "antd";
import { ErrorModalService } from "../components/ErrorHandling/ErrorModalService";
import LandingCards from "./LandingCards/LandingCards";
import { LoadingOutlined } from "@ant-design/icons";
import SignatureParametersContext from "../store/SignatureParametersContext";
import { Toolbar } from "../components/SiteHeader";
import IntroTooltip from "../components/IntroTooltip/IntroTooltip";
import IntroTooltipContext from "../store/IntroTooltipContext";
import IntroModal from "../components/IntroModal/IntroModal";
import { applicationSettings } from "../appsettings";

const LandingPage = () => {
  //context
  const signatureParamsCtx = useContext(SignatureParametersContext);
  const tooltipCtx = useContext(IntroTooltipContext);

  const [loadingFile, setLoadingFile] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const hiddenFileInput: any = React.useRef(null);
  const navigation = useNavigate();

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 18, color: "white" }} spin />
  );

  useEffect(() => {
      if (applicationSettings.TOOLTIP_COMPONENT_ENABLED) {
          if (localStorage.getItem("eSignLite_ShowTooltips")) {
              if (localStorage.getItem("eSignLite_ShowTooltips") === "true") {
                  tooltipCtx.setShowTooltips(true);
                  tooltipCtx.setTooltipStep(0);
                  tooltipCtx.setShowIntroModal(false);
              } else {
                  tooltipCtx.setShowTooltips(false);
                  tooltipCtx.setTooltipStep(tooltipCtx.currentTooltipStep);
                  tooltipCtx.setShowIntroModal(false);
              }
          } else {
              tooltipCtx.setShowIntroModal(true);
          }
      }
  }, []);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleHideTooltipButton = () => {
    tooltipCtx.setTooltipStep(tooltipCtx.currentTooltipStep! + 1);
  };

  const handleChange = async (event: any) => {
    if (event.target.files[0].type === "application/pdf") {
      setButtonLoading(true);
      let convertPdf = await toUint8Array(event.target.files[0]);
      signatureParamsCtx.pdfFile = convertPdf;
      signatureParamsCtx.predefinedSignatureOptions = false;
      signatureParamsCtx.storageId = undefined;
      signatureParamsCtx.signatureToken = undefined;
      signatureParamsCtx.rejectUrl = undefined;
      tooltipCtx.setTooltipStep(1);
      navigation("/sign");
    } else {
      new ErrorModalService(4).showErrorModal();
      setButtonLoading(false);
    }
  };

  const toUint8Array = async (file: File) => {
    let tempFile = file as Blob;
    let convertedFile = await tempFile.arrayBuffer();
    return new Uint8Array(convertedFile);
  };

  if (loadingFile) {
    return (
      <>
        <div className={styles.LandingPageBody}>
          <Spin size="large" style={{ marginTop: "150px" }} />
        </div>
      </>
    );
  }

  return (
    <>
      <div className={styles.LandingPageBody}>
        <Toolbar showlanguage={true} />
        <img
          src={PoweredByLogo}
          style={{ width: "15em" }}
          alt={"Vizibit Logo"}
        />
        <br />
        <div style={{ width: "80%", display: "flex", flexDirection: "column", alignItems: "center" }}>
          <span style={{ textAlign: "center" }}>{i18next.t("description")}</span>
            <span style={{ textAlign: "center" }}>{i18next.t("description_two")}</span>
        </div>
        <br />
        <div className={styles.LandingPageMockupDoc}>
          <DropZone />
        </div>
        <IntroModal initialStep={0} />
      </div>
      <IntroTooltip
        tooltipTextIndex={0}
        open={
          tooltipCtx.showTooltips === true &&
          tooltipCtx.currentTooltipStep === 0
        }
        placement={"top"}
        hideTooltip={handleHideTooltipButton}
      >
        <button
          style={{
            alignSelf: "center",
            marginBottom: "30px",
            background: "none",
            border: "none",
          }}
          type={"button"}
          onClick={handleClick}
          disabled={buttonLoading}
        >
          {buttonLoading ? (
            <div className={styles.UploadButton}>
              <Spin indicator={antIcon} />
            </div>
          ) : (
            <div
              className={
                tooltipCtx.showTooltips === true &&
                tooltipCtx.currentTooltipStep === 0
                  ? styles.UploadButtonAnimation
                  : styles.UploadButton
              }
            >
              {i18next.t("upload_button")}
            </div>
          )}

          <input
            id="contained-button-file"
            type="file"
            accept="application/pdf"
            ref={hiddenFileInput}
            onChange={handleChange}
            style={{ display: "none" }}
          />
        </button>
      </IntroTooltip>

      <div
        style={{
          minHeight: "10rem",
          width: "100%",
          backgroundColor: "#623D91",
          marginBottom: "-10rem",
        }}
      ></div>
      <div style={{ width: "100%" }}>
        <LandingCards />
      </div>
      <div className={styles.bottomBanner}>
        <img src={Vizibit} style={{ height: "3em" }} alt={"Vizibit Logo"} />
      </div>
    </>
  );
};

export default LandingPage;
