import i18next from "i18next";
import { useCallback, useContext, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { useNavigate } from "react-router-dom";
import { ErrorModalService } from "../ErrorHandling/ErrorModalService";
import SignatureParametersContext from "../../store/SignatureParametersContext";

const baseStyle = {
  display: "flex",
  alignItems: "center",
  padding: "20px",
  width: "80%",
  height: "80%",
  borderWidth: 3,
  borderRadius: 5,
  borderColor: "rgb(163 148 148)",
  borderStyle: "dashed",
  backgroundColor: "rgba(249,245,255,0)",
  color: "rgb(163 148 148)",
  transition: "border .3s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

function DropzoneComponent(props: any) {
  const navigation = useNavigate();

  const signatureParamsCtx = useContext(SignatureParametersContext);

  const onDropAccepted = useCallback(async (acceptedFiles) => {
    let convertPdf = await toUint8Array(acceptedFiles[0]);
    signatureParamsCtx.pdfFile = convertPdf;
    signatureParamsCtx.predefinedSignatureOptions = false;
    signatureParamsCtx.storageId = undefined;
    signatureParamsCtx.signatureToken = undefined;
    signatureParamsCtx.rejectUrl = undefined;
    navigation("/sign");
  }, []);

  const onDropRejected = useCallback(async (rejectedFiles) => {
    if (rejectedFiles.length > 1) {
      new ErrorModalService(4).showErrorModal();
    } else {
      new ErrorModalService(4).showErrorModal();
    }
  }, []);

  const toUint8Array = async (file: File) => {
    let tempFile = file as Blob;
    let convertedFile = await tempFile.arrayBuffer();
    return new Uint8Array(convertedFile);
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDropAccepted,
    onDropRejected,
    accept: "application/pdf",
    multiple: false,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  return (
    <div {...getRootProps({ style })}>
      <input {...getInputProps()} />
      <div style={{ textAlign: "center", fontSize: "large" }}>
        {i18next.t("drag_drop_or_click")}
      </div>
    </div>
  );
}

export default DropzoneComponent;
