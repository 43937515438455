import { ErrorModalService } from "../components/ErrorHandling/ErrorModalService";
import { v4 as uuid } from "uuid";
import axios from "axios";
import { BASE_URL } from "../appsettings";

export const toUint8Array = async (file: File) => {
  let tempFile = file as Blob;
  let convertedFile = await tempFile.arrayBuffer();
  return new Uint8Array(convertedFile);
};

export function splitBase64(signatureImage: string) {
  let splitData = (signatureImage as string)?.split(";base64,");
  if (splitData?.length === 2) {
    return splitData[1];
  } else {
    return splitData[0];
  }
}

export const base64_arraybuffer = async (data: any) => {
  const base64url = (await new Promise((r) => {
    const reader = new FileReader();
    reader.onload = () => r(reader.result);
    reader.readAsDataURL(new Blob([data]));
  })) as any;

  return base64url.split(",", 2)[1];
};

export const base64_arraybuffer_no_split = async (data: any) => {
  const base64url = (await new Promise((r) => {
    const reader = new FileReader();
    reader.onload = () => r(reader.result);
    reader.readAsDataURL(new Blob([data]));
  })) as any;

  return base64url;
};

export function base64toBlob(base64Data: string, contentType: string) {
  contentType = contentType || "";
  let sliceSize = 1024;
  let byteCharacters = atob(base64Data);
  let bytesLength = byteCharacters.length;
  let slicesCount = Math.ceil(bytesLength / sliceSize);
  let byteArrays = new Array(slicesCount);

  for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    let begin = sliceIndex * sliceSize;
    let end = Math.min(begin + sliceSize, bytesLength);

    let bytes = new Array(end - begin);
    for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return new Blob(byteArrays, { type: contentType });
}

export const blobPdfFromBase64String = (base64String: string) => {
  const byteArray = Uint8Array.from(
    atob(base64String)
      .split("")
      .map((char) => char.charCodeAt(0))
  );
  return new Blob([byteArray], { type: "application/pdf" });
};

export const createSignatureProfileWithImageInLocalStorage = (
  signatureImage: string,
  name: string,
  phoneNumber: string,
  sigLevel: string,
  jurisdiction: string
) => {
  if (sigLevel === "SES" && jurisdiction === "ZERTES") {
    return;
  } else {
    let checkLocalStorageProfiles = localStorage.getObj(
      "eSignLiteSignatures"
    ) as Array<any>;

    const unique_id = uuid();

    if (
      checkLocalStorageProfiles === null ||
      checkLocalStorageProfiles === undefined
    ) {
      try {
        localStorage.setObj("eSignLiteSignatures", [
          {
            txtMobitel: phoneNumber,
            txtSigImageProfileName: `Default ${sigLevel} profile`,
            txtName: name,
            txtSignatureLevel: sigLevel,
            txtSignatureProvider: "SwisscomAis",
            txtSignatureStandard: jurisdiction,
            txtSignatureImagePicture: signatureImage,
            txtType: "IMAGE",
            default: false,
            profileId: unique_id,
          },
        ]);
      } catch (e) {
        new ErrorModalService(29).showErrorModal();
        return;
      }
    } else {
      checkLocalStorageProfiles.push({
        txtMobitel: phoneNumber,
        txtSigImageProfileName: `Default ${sigLevel} profile`,
        txtSignatureLevel: sigLevel,
        txtName: name,
        txtSignatureProvider: "SwisscomAis",
        txtSignatureStandard: jurisdiction,
        txtSignatureImagePicture: signatureImage,
        txtType: "IMAGE",
        default: false,
        profileId: unique_id,
      });
      try {
        localStorage.setObj("eSignLiteSignatures", checkLocalStorageProfiles);
      } catch (e) {
        new ErrorModalService(29).showErrorModal();
        return;
      }
    }
  }
};

export const createSignatureProfileWithoutImageInLocalStorage = (
  name: string,
  phoneNumber: string,
  sigLevel: string,
  jurisdiction: string
) => {
  if (sigLevel === "SES" && jurisdiction === "ZERTES") {
    return;
  } else {
    let checkLocalStorageProfilesNoImage = localStorage.getObj(
      "eSignLiteSignaturesOnly"
    ) as Array<any>;

    const unique_id = uuid();

    if (
      checkLocalStorageProfilesNoImage === null ||
      checkLocalStorageProfilesNoImage === undefined
    ) {
      try {
        localStorage.setObj("eSignLiteSignaturesOnly", [
          {
            txtMobitel: phoneNumber,
            txtSigImageProfileName: `Default ${sigLevel} profile`,
            txtSignatureLevel: sigLevel,
            txtSignatureProvider: "SwisscomAis",
            txtSignatureStandard: jurisdiction,
            txtType: "NO_IMAGE",
            default: false,
            profileId: unique_id,
          },
        ]);
      } catch (e) {
        new ErrorModalService(29).showErrorModal();
        return;
      }
    } else {
      checkLocalStorageProfilesNoImage.push({
        txtMobitel: phoneNumber,
        txtSigImageProfileName: `Default ${sigLevel} profile`,
        txtSignatureLevel: sigLevel,
        txtSignatureProvider: "SwisscomAis",
        txtSignatureStandard: jurisdiction,
        txtType: "NO_IMAGE",
        default: false,
        profileId: unique_id,
      });
      try {
        localStorage.setObj(
          "eSignLiteSignaturesOnly",
          checkLocalStorageProfilesNoImage
        );
      } catch (e) {
        new ErrorModalService(29).showErrorModal();
        return;
      }
    }
  }
};

export const createSignatureImage = async (profile: any): Promise<any> => {
  let responseImage = undefined;

  await axios
    .post(
      BASE_URL + "/v/1/signer/pdf/signature/image/preview",
      {
        Page: 0,
        WidgetOffsetX: 231,
        WidgetOffsetY: 359,
        WidgetHeight: 75,
        WidgetWidth: 150,
        ImageData: profile.txtSignatureImagePicture,
        LineOne: profile.txtName,
        LineTwo: profile.txtPosition,
        LineThree: profile.txtDepartment,
        LineFour: profile.txtCompanyName,
        LogoImageData: null,
        Appearance: "CARD",
      },
      { responseType: "arraybuffer" }
    )
    .then(async (response) => {
      return await base64_arraybuffer_no_split(response.data);
    })
    .then((response) => {
      responseImage = response;
    })
    .catch((e) => {
      throw new Error("ERROR");
    });

  return responseImage;
};
