import styles from "./ProfilesSidebar.module.css";
import BigActionButton from "@signator/signator_react_components/dist/components/BigActionButton";
import InfoIcon from "../../images/info_icon_grey.svg";
import PenIcon from "../../images/pen_icon_white.svg";
import i18next from "i18next";
import React, { useContext, useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import SignatureParametersContext from "../../store/SignatureParametersContext";
import { ProfileCard } from "./ProfileCard";
import { ProfileCardNoImage } from "./ProfileCardNoImage";
import DeleteContextMenu from "../SignContextMenu/DeleteContextMenu";
import AddIcon from "../../images/plus_icon_white.svg"

interface IProfilesSidebar {
  signingDrawerWithImageStateHandler: Function;
  signingDrawerWithoutImageStateHandler: Function;
  onDragStart: any;
  addSignature: boolean;
  selectedProfile: any;
  setSelectedProfile: any;
  checkedRadioButton: any;
  setCheckedRadioButton: any;
  createSignatureImageOnClick: (profile: any) => void
}
export const ProfilesSidebar = (props: IProfilesSidebar) => {
  const [profiles, setProfiles] = useState([] as any[]);
  const [loading, setLoading] = useState(true);
  const [updateProfiles, setUpdateProfiles] = useState(new Date());

  const signatureParamsCtx = useContext(SignatureParametersContext);

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  useEffect(() => {
    if (!loading) {
      setLoading(true);
    }

    let timeout = setTimeout(() => {
      setLoading(false);
    }, 500);

    if (signatureParamsCtx.signatureType === "image") {
      props.setSelectedProfile({});
      props.setCheckedRadioButton(undefined);
      let fetchProfiles = localStorage.getObj(
        "eSignLiteSignatures"
      ) as Array<any>;

      if (
        fetchProfiles &&
        fetchProfiles.filter((item) => item.txtType === "IMAGE").length > 0 &&
        !signatureParamsCtx.predefinedSignatureOptions
      ) {
        setProfiles(fetchProfiles);
      }

      if (fetchProfiles && signatureParamsCtx.predefinedSignatureOptions) {
        let filteredProfiles = fetchProfiles.filter(
          (item) =>
            item.txtType === "IMAGE" &&
            item.txtSignatureLevel === signatureParamsCtx.signatureLevel &&
            item.txtSignatureStandard === signatureParamsCtx.jurisdiction &&
            item.txtSignatureProvider === signatureParamsCtx.signatureProvider
        );

        if (filteredProfiles.length > 0 && !signatureParamsCtx.phoneNumber) {
          setProfiles(filteredProfiles);
        } else if (
          filteredProfiles.length > 0 &&
          signatureParamsCtx.phoneNumber
        ) {
          let getSignatureProfilesWithMatchingPhoneNumber =
            fetchProfiles.filter(
              (item) =>
                item.txtType === "IMAGE" &&
                item.txtSignatureLevel === signatureParamsCtx.signatureLevel &&
                item.txtSignatureStandard === signatureParamsCtx.jurisdiction &&
                item.txtSignatureProvider ===
                  signatureParamsCtx.signatureProvider &&
                (item.txtMobitel ===
                signatureParamsCtx.phoneNumber?.startsWith("+")
                  ? signatureParamsCtx.phoneNumber?.substring(1)
                  : signatureParamsCtx.phoneNumber)
            );
          setProfiles(getSignatureProfilesWithMatchingPhoneNumber);
        } else {
          setProfiles([]);
        }
      }

      if (fetchProfiles === undefined || fetchProfiles.length === 0) {
        setProfiles([]);
      }
    }

    if (signatureParamsCtx.signatureType === "noimage") {
      let fetchProfiles = localStorage.getObj(
        "eSignLiteSignaturesOnly"
      ) as Array<any>;

      if (
        fetchProfiles &&
        fetchProfiles.filter((item) => item.txtType === "NO_IMAGE").length >
          0 &&
        !signatureParamsCtx.predefinedSignatureOptions
      ) {
        setProfiles(fetchProfiles);
      }

      if (fetchProfiles && signatureParamsCtx.predefinedSignatureOptions) {
        let filteredProfiles = fetchProfiles.filter(
          (item) =>
            item.txtType === "NO_IMAGE" &&
            item.txtSignatureLevel === signatureParamsCtx.signatureLevel &&
            item.txtSignatureStandard === signatureParamsCtx.jurisdiction &&
            item.txtSignatureProvider === signatureParamsCtx.signatureProvider
        );

        if (filteredProfiles.length > 0 && !signatureParamsCtx.phoneNumber) {
          setProfiles(filteredProfiles);
        } else if (
          filteredProfiles.length > 0 &&
          signatureParamsCtx.phoneNumber
        ) {
          let getSignatureProfilesWithMatchingPhoneNumber =
            fetchProfiles.filter(
              (item) =>
                item.txtType === "NO_IMAGE" &&
                item.txtSignatureLevel === signatureParamsCtx.signatureLevel &&
                item.txtSignatureStandard === signatureParamsCtx.jurisdiction &&
                item.txtSignatureProvider ===
                  signatureParamsCtx.signatureProvider &&
                (item.txtMobitel ===
                signatureParamsCtx.phoneNumber?.startsWith("+")
                  ? signatureParamsCtx.phoneNumber?.substring(1)
                  : signatureParamsCtx.phoneNumber)
            );
          setProfiles(getSignatureProfilesWithMatchingPhoneNumber);
        } else {
          setProfiles([]);
        }
      }

      if (fetchProfiles === undefined || fetchProfiles.length === 0) {
        setProfiles([]);
      }
    }

    return () => clearTimeout(timeout);
  }, [
    localStorage.getItem("eSignLiteSignatures"),
    localStorage.getItem("eSignLiteSignaturesOnly"),
    signatureParamsCtx.signatureType,
    updateProfiles,
  ]);

  const removeSavedSignatureProfileHandler = (profileId: any) => {
    let profiles = localStorage.getObj("eSignLiteSignaturesOnly") as Array<any>;

    try {
      let profileToDelete = profiles.findIndex(
        (item) => item.profileId === profileId
      );
      profiles?.splice(profileToDelete, 1);
      if (profiles?.length === 0) {
        localStorage.removeItem("eSignLiteSignaturesOnly");
        setUpdateProfiles(new Date());
      } else {
        localStorage.setObj("eSignLiteSignaturesOnly", profiles);
        setUpdateProfiles(new Date());
      }
    } catch (e) {
      console.log(e);
    }
  };

  const removeSavedSignatureImageProfileHandler = (profileId: any) => {
    let profiles = localStorage.getObj("eSignLiteSignatures") as Array<any>;

    try {
      let profileToDelete = profiles.findIndex(
        (item) => item.profileId === profileId
      );
      profiles?.splice(profileToDelete, 1);
      if (profiles?.length === 0) {
        localStorage.removeItem("eSignLiteSignatures");
        setUpdateProfiles(new Date());
      } else {
        localStorage.setObj("eSignLiteSignatures", profiles);
        setUpdateProfiles(new Date());
      }
    } catch (e) {
      console.log(e);
    }
  };

  if (signatureParamsCtx.signatureType === "noimage") {
    return (
      <div className={styles.signatorProfilesSidebar}>
        <div className={styles.createNewSidebarContainer}>
          <BigActionButton
            btnText={i18next.t("create_new_no_image")}
            onClickFunction={() => {
              props.setSelectedProfile({});
              props.setCheckedRadioButton(undefined);
              props.signingDrawerWithoutImageStateHandler();
            }}
            btnIcon={AddIcon}
          />
        </div>
        <div
          style={{
            fontSize: "11px",
            textAlign: "center",
            paddingLeft: "30px",
            paddingRight: "30px",
          }}
        >
          Choose a signature and then click on the "Sign" button.
        </div>
        <div
          className={styles.profilesContainer}
          style={{
            justifyContent:
              profiles && profiles.length > 0 ? "flex-start" : "center",
          }}
        >
          {loading && (
            <Spin
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "70px",
              }}
              indicator={antIcon}
            />
          )}
          {!loading && profiles.length === 0 && (
            <>
              <img
                src={InfoIcon}
                height={50}
                width={50}
                style={{ marginTop: "20px" }}
              />
              <span
                style={{ color: "gray", width: "70%", textAlign: "center" }}
              >
                No available signature images
              </span>
            </>
          )}
          {!loading &&
            profiles.length > 0 &&
            profiles.map((profile, index) => {
              return (
                <DeleteContextMenu
                  removeProfileHandler={() =>
                    removeSavedSignatureProfileHandler(profile.profileId)
                  }
                  key={index}
                >
                  <ProfileCardNoImage
                    profile={profile}
                    selectedProfile={props.selectedProfile}
                    setSelectedProfile={props.setSelectedProfile}
                    checkedRadioButton={props.checkedRadioButton}
                    setCheckedRadioButton={props.setCheckedRadioButton}
                  />
                </DeleteContextMenu>
              );
            })}
        </div>
      </div>
    );
  }

  return (
    <div className={styles.signatorProfilesSidebar}>
      <div className={styles.createNewSidebarContainer}>
        <BigActionButton
          btnText={i18next.t("create_new_profile")}
          onClickFunction={() => props.signingDrawerWithImageStateHandler()}
          btnIcon={AddIcon}
        />
      </div>
      <div
        style={{
          fontSize: "11px",
          textAlign: "center",
          paddingLeft: "30px",
          paddingRight: "30px",
        }}
      >
        Click on signature image or drag and drop it to the document.
      </div>
      <div
        className={styles.profilesContainer}
        style={{
          justifyContent:
            profiles && profiles.length > 0 ? "flex-start" : "center",
        }}
      >
        {loading && (
          <Spin
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "70px",
            }}
            indicator={antIcon}
          />
        )}
        {!loading && profiles.length === 0 && (
          <>
            <img
              src={InfoIcon}
              height={50}
              width={50}
              style={{ marginTop: "20px" }}
            />
            <span style={{ color: "gray", width: "70%", textAlign: "center" }}>
              No available signature images
            </span>
          </>
        )}
        {!loading &&
          profiles.length > 0 &&
          profiles.map((profile, index) => {
            return (
              <DeleteContextMenu
                removeProfileHandler={() =>
                  removeSavedSignatureImageProfileHandler(profile.profileId)
                }
                key={index}
              >
                <ProfileCard
                  profile={profile}
                  onDragStart={props.onDragStart}
                  addSignature={props.addSignature}
                  createSignatureImageOnClick={props.createSignatureImageOnClick}
                />
              </DeleteContextMenu>
            );
          })}
      </div>
    </div>
  );
};
