import i18next from "i18next";
import { Checkbox, Input, Modal, Radio } from "antd";
import { Field, Form } from "react-final-form";
import React, { useEffect, useState } from "react";
import { Telephone } from "../Telephone";
import styles from "./FirstTimeVisitorModal.module.css";
import {
  createSignatureProfileWithImageInLocalStorage,
  createSignatureProfileWithoutImageInLocalStorage,
  splitBase64,
} from "../../services/utilityServices";
import { ErrorModalService } from "../ErrorHandling/ErrorModalService";
import { Loader } from "../Loader";
import EuFlag from "../../images/EU.png";
import ChFlag from "../../images/CH.png";
import {QuestionCircleOutlined} from "@ant-design/icons";

interface IFirstTimeVisitorModal {
  showModal: boolean;
  closeModal: Function;
  firstName: string | undefined | null;
  lastName: string | undefined | null;
  phoneNumber: string | undefined | null;
  jurisdiction: string | undefined | null;
}

export const FirstTimeVisitorModal = ({jurisdiction, firstName, lastName, phoneNumber, showModal, closeModal}: IFirstTimeVisitorModal) => {
  const [name, setName] = useState("");
  const [acceptTC, setAcceptTC] = useState(false);
  const [loading, setLoading] = useState(false);
  const [jurisdictionState, setJurisdictionState] = useState(jurisdiction);

  useEffect(() => {
    if (firstName && lastName) {
      setName(firstName + " " + lastName);
    }
  }, []);

  const submitCreateDefaultProfile = (values: any) => {
    if (name === "" || name === undefined || acceptTC === false) {
      console.log("Missing required fields");
      new ErrorModalService(31).showErrorModal();
      return;
    } else {
      setLoading(true);
      document.fonts.load("10vmin Caveat").then(() => {
        let canvas = document.createElement("canvas");
        canvas.width = 600;
        canvas.height = 300;
        let ctx = canvas.getContext("2d");
        ctx!.textAlign = "center";
        ctx!.font = "10vmin Caveat";
        ctx!.fillText(name, 300, 150);
        let signatureImage = document.createElement("img");
        signatureImage.src = canvas.toDataURL();
        let signatureImageBase64 = splitBase64(signatureImage.src);

        try {
          localStorage.setItem("eSignLiteTCAccepted", "true");

          createSignatureProfileWithImageInLocalStorage(
            signatureImageBase64,
            name,
            values.profilePhoneNumber,
            "SES",
            "EIDAS"
          );
          createSignatureProfileWithImageInLocalStorage(
            signatureImageBase64,
            name,
            values.profilePhoneNumber,
            "AES",
            "EIDAS"
          );
          createSignatureProfileWithImageInLocalStorage(
            signatureImageBase64,
            name,
            values.profilePhoneNumber,
            "QES",
            "EIDAS"
          );
          createSignatureProfileWithoutImageInLocalStorage(
            name,
            values.profilePhoneNumber,
            "SES",
            "EIDAS"
          );
          createSignatureProfileWithoutImageInLocalStorage(
            name,
            values.profilePhoneNumber,
            "AES",
              "EIDAS"
          );
          createSignatureProfileWithoutImageInLocalStorage(
            name,
            values.profilePhoneNumber,
            "QES",
              "EIDAS"
          );

          createSignatureProfileWithImageInLocalStorage(
              signatureImageBase64,
              name,
              values.profilePhoneNumber,
              "AES",
              "ZERTES"
          );
          createSignatureProfileWithImageInLocalStorage(
              signatureImageBase64,
              name,
              values.profilePhoneNumber,
              "QES",
              "ZERTES"
          );
          createSignatureProfileWithoutImageInLocalStorage(
              name,
              values.profilePhoneNumber,
              "AES",
              "ZERTES"
          );
          createSignatureProfileWithoutImageInLocalStorage(
              name,
              values.profilePhoneNumber,
              "QES",
              "ZERTES"
          );

          setTimeout(() => {
            closeModal();
            setLoading(false);
          }, 500);
        } catch (e) {
          new ErrorModalService(29).showErrorModal();
          setLoading(false);
          return;
        }
      });
    }
  };

  return (
    <Modal
      visible={showModal}
      title={<div>{i18next.t("create_default_profile_title")}<hr/></div>}
      footer={null}
      onCancel={() => closeModal()}
      maskClosable={false}
    >
      {loading ? (
        <Loader />
      ) : (
        <Form
          onSubmit={submitCreateDefaultProfile}
          initialValues={{
            profilePhoneNumber: phoneNumber
              ? phoneNumber.startsWith("+")
                ? phoneNumber
                : "+" + phoneNumber.trim()
              : undefined,
            signatureStandard: jurisdictionState,
          }}
        >
          {({ handleSubmit, values }) => {
            return (
              <form id="default-profiles-form" onSubmit={handleSubmit}>
                <div className={styles.SignatorProfilesForm}>
                  <div className={styles.SignatorProfilesForm_Text}>
                    <div className={styles.SignatorProfilesForm_Text_Title}>
                      {i18next.t("welcome_modal_title")}
                    </div>
                    <div className={styles.SignatorProfilesForm_Text_Subtitle}>
                      {i18next.t("welcome_modal_message")} <br />
                      {i18next.t("welcome_modal_message_part_two")}
                    </div>
                  </div>
                  <div style={{ width: "100%", marginBottom: "15px" }}>
                    <Input
                      style={{
                        marginBottom: "15px",
                        height: "40px",
                        borderRadius: "0.25rem",
                      }}
                      placeholder={i18next.t("first_and_last_name")}
                      name={"profileName"}
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                    />
                    <Telephone name={"profilePhoneNumber"} />
                    {/*<div
                      style={{
                        marginTop: "10px",
                        marginBottom: "5px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {i18next.t("jurisdiction_label")}
                      {":"}
                      <span style={{ marginLeft: "4px", paddingBottom: "4px" }}>
                        <a
                          href={
                            "https://vizibit.eu/digital-signatures/#jurisdiction"
                          }
                          target={"_blank"}
                        >
                          <QuestionCircleOutlined
                            style={{ fontSize: "16px" }}
                          />
                        </a>
                      </span>
                    </div>
                      <div className={styles.SignatureStandardRadiosBody}>
                        <Field
                          name={"signatureStandard"}
                          type={"radio"}
                          defaultValue={jurisdiction ?? undefined}
                        >
                          {(props) => (
                            <Radio.Group
                              style={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "flex-start",
                                flexDirection: "column",
                                gap: "5px",
                              }}
                              defaultValue={jurisdiction ?? undefined}
                              disabled={
                                (jurisdiction === "ZERTES" ||
                                  jurisdiction === "EIDAS") ??
                                false
                              }
                            >
                              <Radio
                                onChange={props.input.onChange}
                                value={"EIDAS"}
                                name={"EIDAS"}
                              >
                                <img
                                  src={EuFlag}
                                  style={{
                                    height: "18px",
                                    marginRight: "5px",
                                    marginLeft: "2px",
                                    marginBottom: "2px",
                                  }}
                                />{" "}
                                EIDAS
                              </Radio>
                              <Radio
                                onChange={props.input.onChange}
                                value={"ZERTES"}
                                name={"ZERTES"}
                              >
                                <img
                                  src={ChFlag}
                                  style={{
                                    height: "18px",
                                    width: "27.42px",
                                    marginRight: "5px",
                                    marginLeft: "2px",
                                    marginBottom: "2px",
                                  }}
                                />{" "}
                                ZERTES
                              </Radio>
                            </Radio.Group>
                          )}
                        </Field>
                      </div>
                    */}
                    <Checkbox
                      name={"txtAcceptTC"}
                      style={{
                        marginTop: "15px",
                        display: "flex",
                        justifyContent: "center",
                        color: "#8c8b8b",
                      }}
                      onChange={(e) => setAcceptTC(e.target.checked)}
                    >
                      {i18next.t("accept")}{" "}
                      <a
                        href={
                          "https://docs.google.com/document/d/e/2PACX-1vSI2Y2EleBVYUONHOm94rBDxyk2I1rXh7UUACb5Wn60i4k3uDhn4syZGkd85tgumZiXWgmtEWUnj1m_/pub?urp=gmail_link"
                        }
                        target={"_blank"}
                        rel={"noreferrer"}
                      >
                        {i18next.t("terms_and_conditions")}
                      </a>
                      {" & "}
                      <a
                          href={
                            "https://vizibit.eu/privacy-policy/"
                          }
                          target={"_blank"}
                          rel={"noreferrer"}
                      >
                        {i18next.t("privacy_policy")}
                      </a>
                    </Checkbox>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      justifyContent: "center",
                      gap: "20px",
                    }}
                  >
                    <button
                      disabled={
                        acceptTC === false ||
                        name === "" ||
                        name === undefined
                      }
                      type={"submit"}
                      className={styles.SignStepButton}
                    >
                      {i18next.t("create_label")}
                    </button>
                  </div>
                </div>
              </form>
            );
          }}
        </Form>
      )}
    </Modal>
  );
};