import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import {
  DocumentLoadEvent,
  Icon,
  LoadError,
  Plugin,
  RenderPage,
  RenderPageProps,
  SpecialZoomLevel,
  Viewer,
  Worker,
  ZoomEvent,
} from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import {
  toolbarPlugin,
  ToolbarProps,
  ToolbarSlot,
} from "@react-pdf-viewer/toolbar";
import { getFilePlugin } from "@react-pdf-viewer/get-file";
import DefaultPdf from "../assets/blank.pdf";
import {
  base64_arraybuffer,
  createSignatureImage,
} from "../services/utilityServices";
import styles from "./Signer.module.css";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "@react-pdf-viewer/drop/lib/styles/index.css";
import { Checkbox, Modal, Spin } from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";

import Moveable from "react-moveable";
import { ErrorModalService } from "../components/ErrorHandling/ErrorModalService";
import SigningWizard from "./SigningWizard/SigningWizard";
import SigImageTemplateEN from "../images/sigImageTemplate_EN.png";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import { LoadingOutlined } from "@ant-design/icons";
import { applicationSettings } from "../appsettings";
import i18next from "i18next";
import SignatureParametersContext from "../store/SignatureParametersContext";
import SignatureMoveable from "../components/SignatureMoveable/SignatureMoveable";
import PdfViewerTopToolbar from "../components/PdfViewerTopToolbar/PdfViewerTopToolbar";
import SignContextMenu from "../components/SignContextMenu/SignContextMenu";
import IntroTooltipContext from "../store/IntroTooltipContext";
import IntroModal from "../components/IntroModal/IntroModal";
import PdfViewerBottomToolbar from "../components/PdfViewerBottomToolbar/PdfViewerBottomToolbar";
import { signatureFrameReducer } from "../store/SignatureFrameReducer";
import { FirstTimeVisitorModal } from "../components/FirstTimeVisitorModal/FirstTimeVisitorModal";
import axios from "axios";
import { ProfilesSidebar } from "../components/ProfilesSidebar/ProfilesSidebar";
import potpisTemplate from "../images/potpis_template.png";
import { RailComponent } from "@signator/signator_react_components";
import signWithImageIcon from "../images/slika02.svg";
import signWithoutImageIcon from "../images/bez-slike02.svg";

//reject modal
const rejectInfo = (rejectUrl: string) => {
  Modal.confirm({
    title: (
      <div>
        {i18next.t("reject_signing_header")}
        <hr />
      </div>
    ),
    content: (
      <div>
        <p>{i18next.t("reject_signing_info")}</p>
      </div>
    ),
    async onOk() {
      await axios
        .get(rejectUrl)
        .then(() => {
          rejectSuccessInfo();
        })
        .catch((_) => {
          rejectUnsuccessInfo();
        });
    },
    okText: i18next.t("confirm_label_lower"),
    cancelText: i18next.t("cancel_label_lower"),
    closable: true,
    okButtonProps: { danger: true },
    cancelButtonProps: { danger: true, type: "default" },
    autoFocusButton: null,
  });
};

//reject successful modal
const rejectSuccessInfo = () => {
  Modal.success({
    title: i18next.t("reject_success"),
    content: (
      <div>
        <p>{i18next.t("reject_success_text")}</p>
      </div>
    ),
    onOk() {
      setTimeout(() => {
        window.close();
      }, 300);

      setTimeout(() => {
        window.location.replace("https://vizibit.eu");
      }, 1500);
    },
    okText: "OK",
    okButtonProps: { danger: true },
    cancelButtonProps: { danger: true, type: "default" },
    autoFocusButton: null,
  });
};

//reject unsuccessful modal
const rejectUnsuccessInfo = () => {
  Modal.error({
    title: i18next.t("reject_failed"),
    content: (
      <div>
        <p>{i18next.t("reject_failed_text")}</p>
      </div>
    ),
    closable: true,
    okButtonProps: { danger: true },
    cancelButtonProps: { danger: true, type: "default" },
    autoFocusButton: null,
  });
};

const Signer = () => {
  //signature parameters & tooltip context
  const signatureParamsCtx = useContext(SignatureParametersContext);
  const tooltipCtx = useContext(IntroTooltipContext);

  //initial variables and state
  const [signatureType, setSignatureType] = useState<"image" | "noimage">(
    signatureParamsCtx.signatureType
  );
  const [width, setWidth] = useState<number>(window.innerWidth);
  const isMobileDefaultZoomLevel = width <= 980;
  const isMobile = width <= 1200;
  const shouldDisplaySidebar = width <= 1200;
  const [signingDrawerState, setSigningDrawerState] = useState({
    open: false,
    startingPage: 0,
    signingWithImage: false,
    addNewProfile: false,
    signWithImageContinue: false,
    signingImageProfile: {},
    restartVideoIdentDrawer: false,
    createdSignatureImage: undefined,
    directSignWithoutImage: false,
  });
  const [signatureFrame, setSignatureFrame] = useReducer(
    signatureFrameReducer,
    {
      size: [
        150 * (isMobileDefaultZoomLevel ? 0.5 : 1.25),
        75 * (isMobileDefaultZoomLevel ? 0.5 : 1.25),
      ],
      translate: [
        100 * (isMobileDefaultZoomLevel ? 0.5 : 1.25),
        100 * (isMobileDefaultZoomLevel ? 0.5 : 1.25),
      ],
      rotate: 0,
      transformOrigin: "50% 50%",
      scale: isMobileDefaultZoomLevel ? 0.5 : 1.25,
      opacity: "100%",
      signaturePage: 1,
    }
  );
  const [moveableTarget, setMoveableTarget] = useState<HTMLElement | undefined>(
    undefined
  );
  const [addSignature, setAddSignature] = useState(false);
  const [loadingState, setLoadingState] = useState(true);
  const [errorStatePdf, setErrorStatePdf] = useState(false);
  const [signWithoutImageModal, setSignWithoutImageModal] = useState({
    open: false,
    rememberChoice: false,
  });
  const [firstTimeVisitorModal, setFirstTimeVisitorModal] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState({});
  const [checkedRadioButton, setCheckedRadioButton] = useState(undefined);

  //pdf viewer instances
  const getFilePluginInstance = getFilePlugin({});
  const toolbarPluginInstance = toolbarPlugin();
  const { Toolbar } = toolbarPluginInstance;

  //variables
  const signatureImageToSignWithData = useRef<object>({});
  const page = useRef<number>(0);
  const moveableRef = useRef<Moveable>(null);
  const sigCanvas = useRef({});

  //icon for spinner
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 48, color: "white" }} spin />
  );

  //getting signature page element, and setting bounds for sig image
  let signaturePageElement = document.getElementById("signature-page");
  let bounds = signaturePageElement?.getBoundingClientRect();

  //getting info of window width
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  //effect for initial checks
  useEffect(() => {
    //if pdf file is not defined, set error state and dont show toolbar
    if (signatureParamsCtx.pdfFile === undefined) {
      setErrorStatePdf(true);
      window.location.replace(window.location.origin);
    } else {
      //check if we need to show tooltips
      if (applicationSettings.TOOLTIP_COMPONENT_ENABLED) {
        if (localStorage.getItem("eSignLite_ShowTooltips")) {
          if (localStorage.getItem("eSignLite_ShowTooltips") === "true") {
            tooltipCtx.setShowTooltips(true);
            tooltipCtx.setTooltipStep(1);
            tooltipCtx.setShowIntroModal(false);
          } else {
            tooltipCtx.setShowTooltips(false);
            tooltipCtx.setTooltipStep(0);
            tooltipCtx.setShowIntroModal(false);
          }
        } else {
          setTimeout(() => {
            tooltipCtx.setShowIntroModal(true);
          }, 1500);
        }
      }
    }

    //check for restart video identification state
    if (
      signatureParamsCtx.targetIdentUrl !== null &&
      signatureParamsCtx.targetIdentUrl !== undefined
    ) {
      setSigningDrawerState((prevState) => ({
        ...prevState,
        restartVideoIdentDrawer: true,
      }));
    }

    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  //const handlers
  //handler for warn about signing without image checkbox
  const handleRememberChoiceCheckbox = useCallback((e: CheckboxChangeEvent) => {
    setSignWithoutImageModal((prevState) => ({
      ...prevState,
      rememberChoice: e.target.checked,
    }));

    localStorage.setItem(
      "eSignLiteSignatureImageWarning",
      e.target.checked.toString()
    );
  }, []);

  //handler for showing modal with warning about signature image
  const showSignWithoutImageModal = useCallback(() => {
    let warnAboutImage = localStorage.getItem("eSignLiteSignatureImageWarning");

    if (warnAboutImage && warnAboutImage === "true") {
      signingDrawerWithoutImageStateHandler();
    } else {
      setSignWithoutImageModal((prevState) => ({
        ...prevState,
        open: true,
      }));
    }
  }, []);

  //closing Signing without sig. image warning modal handler
  const closeSignWithoutImageModal = useCallback(() => {
    setSignWithoutImageModal((prevState) => ({
      ...prevState,
      open: false,
      rememberChoice: false,
    }));
  }, []);

  //handler for adding created sig. image to the doc
  const signWithCreatedSignatureImageHandler = (
    data: any,
    createdImage: any
  ) => {
    signatureImageToSignWithData.current = data;
    addSigImageInitial();

    setSigningDrawerState((prevState) => ({
      ...prevState,
      open: false,
      startingPage: 0,
      signingWithImage: false,
      addNewProfile: false,
      createdSignatureImage: createdImage,
    }));
  };

  //handler for adding sig. image to the doc from the sidebar
  const createSignatureImageOnClick = (profile: any) => {
    createSignatureImage(profile)
      .then((response) => {
        signatureImageToSignWithData.current = profile;

        setSigningDrawerState((prevState) => ({
          ...prevState,
          open: false,
          startingPage: 0,
          signingWithImage: false,
          addNewProfile: false,
          createdSignatureImage: response,
        }));
      })
        .then(_ => {
          addSigImageInitial();
        })
      .catch((e) => {
        new ErrorModalService(30).showErrorModal();
      });
  };

  //handler for signing the document after sig. image was created & placed on the document
  const signWithImage = () => {
    setSigningDrawerState((prevState) => ({
      ...prevState,
      open: true,
      startingPage: 2,
      signingImageProfile: signatureImageToSignWithData.current,
      signWithImageContinue: true,
    }));
  };

  const directSignWithoutImageHandler = () => {
    setSigningDrawerState((prevState) => ({
      ...prevState,
      open: true,
      startingPage: 2,
      signingImageProfile: selectedProfile,
      directSignWithoutImage: true,
    }));
  };

  //handler for signing the document without sig. image (sign button)
  const signingDrawerWithoutImageStateHandler = () => {
    tooltipCtx.setTooltipStep(3);

    setSigningDrawerState((prevState) => ({
      ...prevState,
      open: !signingDrawerState.open,
      startingPage: 1,
    }));
  };

  //handler for create new sig. button
  const createNewSignatureWithoutImageHandler = () => {
    tooltipCtx.setTooltipStep(3);

    setSigningDrawerState((prevState) => ({
      ...prevState,
      open: !signingDrawerState.open,
      addNewProfile: true,
      startingPage: 1,
    }));
  };

  //handler for signing the document with sig. image (sig. image button)
  const signingDrawerWithImageStateHandler = () => {
    setSigningDrawerState((prevState) => ({
      ...prevState,
      open: !signingDrawerState.open,
      startingPage: 0,
      signingWithImage: true,
    }));

    tooltipCtx.setTooltipStep(2);
  };

  //handler for create new sig. image button
  const createNewSignatureWithImageHandler = () => {
    setSigningDrawerState((prevState) => ({
      ...prevState,
      open: !signingDrawerState.open,
      startingPage: 0,
      signingWithImage: true,
      addNewProfile: true,
    }));

    tooltipCtx.setTooltipStep(2);
  };

  //handler for closing signing drawer
  const closeSigningDrawer = () => {
    setSigningDrawerState((prevState) => ({
      ...prevState,
      open: false,
      startingPage: 0,
      signingWithImage: false,
      addNewProfile: false,
      signWithImageContinue: false,
      signingImageProfile: {},
      directSignWithoutImage: false,
    }));

    tooltipCtx.setTooltipStep(1);
  };

  //logic which happens of every page change - ensuring that signature moveable is correctly set
  const changePageHandler = (e: any) => {
    page.current = e.currentPage;
    //fix for a bug where on a multipage doc signature field would lose it's connection to moveable, because page is not rendered (that's how PDF viewer works)
    let signatureFieldElement = document.getElementById("signature-field");
    if (
      signatureFieldElement &&
      e.currentPage === signatureFrame.signaturePage - 1
    ) {
      setTimeout(() => {
        setMoveableTarget(document.getElementById("signature-field")!);
      }, 200);
    }
  };

  //logic for adding sig. image to the document canvas
  const addSigImageInitial = (pageNumber?: number) => {
    if (!addSignature) {
      setSignatureFrame({
        type: "ON_PAGE_CHANGE",
        signaturePage: pageNumber ?? page.current + 1,
      });
      setAddSignature(true);

      tooltipCtx.setTooltipStep(5);

      setTimeout(() => {
        let signatureImageElement = document.getElementById("signature-field");
        if (signatureImageElement) {
          signatureImageElement.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      }, 500);
    }

    if (addSignature) {
      setTimeout(() => {
        setSignatureFrame({
          type: "ON_PAGE_CHANGE",
          signaturePage: pageNumber ?? page.current + 1,
        });
        setAddSignature(true);
        setTimeout(() => {
          let signatureImageElement =
            document.getElementById("signature-field");
          if (signatureImageElement) {
            signatureImageElement.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
          }
        }, 500);
      }, 200);
    }
  };

  //help tooltip handler
  const handleHideTooltipButton = () => {
    tooltipCtx.setTooltipStep(tooltipCtx.currentTooltipStep! + 1);
  };

  //removing sig. image from doc. canvas
  const removeSignatureHandler = () => {
    moveableRef.current?.destroy();
    setAddSignature(false);
    setSignatureFrame({ type: "ON_REMOVE" });
    tooltipCtx.setTooltipStep(1);
  };

  const handleDocumentLoad = async (e: DocumentLoadEvent) => {
    let base64Pdf = await base64_arraybuffer(e.file.data);
    signatureParamsCtx.pdfFileBase64 = base64Pdf;

    setTimeout(() => {
      setLoadingState(false);

      let doesUserHaveSignatureProfileWithImage = localStorage.getItem(
        "eSignLiteSignatures"
      );

      let doesUserHaveSignatureProfileWithoutImage = localStorage.getItem(
        "eSignLiteSignaturesOnly"
      );

      if (
        !doesUserHaveSignatureProfileWithImage &&
        !doesUserHaveSignatureProfileWithoutImage
      ) {
        setFirstTimeVisitorModal(true);
      }
    }, 500);

    setTimeout(() => {
      let getSignaturePageHtmlElement =
        document.getElementById("signature-page");
      if (getSignaturePageHtmlElement) {
        setSignatureFrame({
          type: "ON_LOAD_TRANSLATE",
          translate: [
            getSignaturePageHtmlElement.getBoundingClientRect().width / 2 -
              signatureFrame.size[0] / 2,
            getSignaturePageHtmlElement.getBoundingClientRect().height / 2 -
              signatureFrame.size[1] / 2,
          ],
        });
      }
    }, 1000);
  };

  const renderToolbar = (Toolbar: (props: ToolbarProps) => ReactElement) => (
    <>
      <Toolbar>
        {(slots: ToolbarSlot) => {
          return (
            <PdfViewerTopToolbar
              slots={slots}
              addSignature={addSignature}
              isMobile={isMobile}
            />
          );
        }}
      </Toolbar>
    </>
  );

  const renderError = (error: LoadError) => {
    let message = "";
    switch (error.name) {
      case "InvalidPDFException":
        new ErrorModalService(13).showErrorModal();
        message = "The document is invalid or corrupted";
        setErrorStatePdf(true);
        break;
      case "MissingPDFException":
        message = "The document is missing";
        setErrorStatePdf(true);
        break;
      case "UnexpectedResponseException":
        setErrorStatePdf(true);
        message = "Unexpected server response";
        break;
      default:
        message = "Cannot load the document";
        setErrorStatePdf(true);
        break;
    }

    return (
      <div
        style={{
          alignItems: "center",
          display: "flex",
          height: "100%",
          justifyContent: "center",
          minHeight: "250px",
        }}
      >
        <div
          style={{
            backgroundColor: "#e53e3e",
            borderRadius: "0.25rem",
            color: "#fff",
            padding: "0.5rem",
          }}
        >
          {message}
        </div>
      </div>
    );
  };

  //Dragging sig. image handler
  const onDragSigner = (event: DragEvent, profile: any) => {
    let dragImageVar = new Image();
    dragImageVar.src = potpisTemplate;
    if (event.dataTransfer) {
      event.dataTransfer.setDragImage(dragImageVar, 10, 10);
      event.dataTransfer.setData("text", JSON.stringify(profile));
    }
  };

  //Dropping sig. image handler
  const onDropSigner = (event: any) => {
    if (
      event.nativeEvent.offsetX + 150 * signatureFrame.scale >
        event.target.clientWidth ||
      event.nativeEvent.offsetX < 0 ||
      event.nativeEvent.offsetY + 75 * signatureFrame.scale >
        event.target.clientHeight ||
      event.nativeEvent.offsetY < 0
    ) {
      return;
    }

    if (!addSignature) {
      try {
        let deserializeProfile = JSON.parse(event.dataTransfer.getData("text"));
        //get signature page number
        let pageNr =
          parseInt(
            event.target.parentElement.parentElement.id.charAt(
              event.target.parentElement.parentElement.id.length - 1
            ) ?? 0
          ) + 1;

        //if pageNr is not a number, then we do not have to update signature page in signatureFrame state, and I can render sig. image on that same page
        createSignatureImage(deserializeProfile)
          .then((response) => {
            setSignatureFrame({
              type: "ON_DRAG",
              translate: [
                event.nativeEvent.offsetX === 0
                  ? event.nativeEvent.layerX
                  : event.nativeEvent.offsetX,
                event.nativeEvent.offsetY === 0
                  ? event.nativeEvent.layerY
                  : event.nativeEvent.offsetY,
              ],
            });
            signatureImageToSignWithData.current = deserializeProfile;
            setSigningDrawerState((prevState) => ({
              ...prevState,
              open: false,
              startingPage: 0,
              signingWithImage: false,
              addNewProfile: false,
              createdSignatureImage: response,
            }));
            addSigImageInitial(isNaN(pageNr) ? undefined : pageNr);
          })
          .catch((e) => {
            new ErrorModalService(30).showErrorModal();
          });
      } catch (e) {
        new ErrorModalService(18).showErrorModal();
      }
    } else {
      new ErrorModalService(33).showErrorModal();
    }
  };

  const allowDrop = (event: any) => {
    event.preventDefault();
  };

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar,
    sidebarTabs: (defaultTabs) =>
      !shouldDisplaySidebar
        ? [
            {
              content: (
                <ProfilesSidebar
                  signingDrawerWithImageStateHandler={
                    createNewSignatureWithImageHandler
                  }
                  signingDrawerWithoutImageStateHandler={
                    createNewSignatureWithoutImageHandler
                  }
                  onDragStart={onDragSigner}
                  addSignature={addSignature}
                  selectedProfile={selectedProfile}
                  setSelectedProfile={setSelectedProfile}
                  checkedRadioButton={checkedRadioButton}
                  setCheckedRadioButton={setCheckedRadioButton}
                  createSignatureImageOnClick={createSignatureImageOnClick}
                />
              ),
              icon: (
                <Icon size={16}>
                  <path d="M23.5,17a1,1,0,0,1-1,1h-11l-4,4V18h-6a1,1,0,0,1-1-1V3a1,1,0,0,1,1-1h21a1,1,0,0,1,1,1Z" />
                  <path d="M5.5 12L18.5 12" />
                  <path d="M5.5 7L18.5 7" />
                </Icon>
              ),
              title: "Test",
            },
          ]
        : [],
  });

  const zoom = (e: ZoomEvent) => {
    setSignatureFrame({ type: "ON_ZOOM", scale: e.scale, opacity: 0 });
    setTimeout(() => {
      setSignatureFrame({ type: "ON_CHANGE_OPACITY", opacity: 1 });
    }, 500);
  };

  const renderPage: RenderPage = (props: RenderPageProps) => {
    if (props.pageIndex === signatureFrame.signaturePage - 1) {
      return (
        <div
          id="signature-page"
          style={{ width: "100%", height: "100%", zIndex: "9999" }}
          onDragOver={allowDrop}
          onDrop={onDropSigner}
        >
          {props.canvasLayer.children}
          {addSignature && (
            <div style={{ opacity: signatureFrame.opacity }}>
              <SignContextMenu
                signWithImage={signWithImage}
                removeSigHandler={removeSignatureHandler}
              >
                <img
                  id="signature-field"
                  className={styles.Signator_SignatureFieldContainer}
                  src={
                    signingDrawerState.createdSignatureImage ??
                    SigImageTemplateEN
                  }
                  style={{
                    height: `${signatureFrame.size[1]}px`,
                    width: `${signatureFrame.size[0]}px`,
                    transform: `translate(${signatureFrame.translate[0]}px, ${signatureFrame.translate[1]}px)`,
                    zIndex: "1",
                    position: "relative",
                  }}
                ></img>
              </SignContextMenu>
              <SignatureMoveable
                addSignature={addSignature}
                signatureFrame={signatureFrame}
                removeSignatureHandler={removeSignatureHandler}
                width={width}
                setSignatureFrame={setSignatureFrame}
                renderProps={props}
              />
            </div>
          )}
          <div style={{ display: "none" }}>
            {props.annotationLayer.children}
            {props.textLayer.children}
          </div>
        </div>
      );
    } else {
      return (
        <div
          id={`signature-page-${props.pageIndex}`}
          style={{ width: "100%", height: "100%", zIndex: "9999" }}
          onDragOver={allowDrop}
          onDrop={onDropSigner}
        >
          {props.canvasLayer.children}
          <div style={{ display: "none" }}>
            {props.annotationLayer.children}
            {props.textLayer.children}
          </div>
        </div>
      );
    }
  };

  //signature image function which sets correct offset on Y axis
  function getOffsetY() {
    if (bounds?.height) {
      let boundsHeight = bounds?.height / signatureFrame.scale;
      let imageOffsetY =
        parseInt(signatureFrame.translate[1]) / signatureFrame.scale;
      let imageHeight = signatureFrame.size[1] / signatureFrame.scale;
      return boundsHeight - imageOffsetY - imageHeight;
    } else {
      return 0;
    }
  }

  const changeSigTypeToImage = () => {
    if (signatureParamsCtx.signatureType !== "image" && !addSignature) {
      signatureParamsCtx.signatureType = "image";
      setSignatureType("image");
    } else {
      return;
    }
  };

  const changeSigTypeToNoImage = () => {
    if (signatureParamsCtx.signatureType !== "noimage" && !addSignature) {
      signatureParamsCtx.signatureType = "noimage";
      setSignatureType("noimage");
    } else {
      return;
    }
  };

  return (
    <div className={styles.PdfSignerOverlay}>
      {!shouldDisplaySidebar && !loadingState && (
        <div className={styles.RailGridContainer}>
          <RailComponent
            railItemsArray={[
              {
                name: "With image",
                icon: signWithImageIcon,
                route: "",
                isSelected: signatureType === "image",
                callbackFn: changeSigTypeToImage,
              },
              {
                name: "Without image",
                icon: signWithoutImageIcon,
                route: "",
                isSelected: signatureType === "noimage",
                callbackFn: changeSigTypeToNoImage,
              },
            ]}
            navigationDisabled={true}
          />
        </div>
      )}
      <SigningWizard
        createNewProfile={signingDrawerState.addNewProfile}
        signingDrawerOpen={signingDrawerState.open}
        signingWithSignatureImage={signingDrawerState.signingWithImage}
        closeSigningDrawer={closeSigningDrawer}
        startingStep={signingDrawerState.startingPage}
        removeSignatureHandler={removeSignatureHandler}
        signWithCreatedSignatureImageHandler={
          signWithCreatedSignatureImageHandler
        }
        signWithImageContinue={signingDrawerState.signWithImageContinue}
        directSignWithoutImage={signingDrawerState.directSignWithoutImage}
        signingImageProfile={
          signingDrawerState.signingImageProfile ?? undefined
        }
        signatureImage={
          addSignature
            ? {
                page: signatureFrame.signaturePage - 1,
                offsetX: signatureFrame.translate[0] / signatureFrame.scale,
                offsetY: getOffsetY(),
                width: signatureFrame.size[0] / signatureFrame.scale,
                height: signatureFrame.size[1] / signatureFrame.scale,
              }
            : undefined
        }
      />
      <div className={styles.PdfSignerViewer}>
        <>
          <Worker workerUrl={pdfjsWorker}>
            <div
              className="rpv-core__viewer"
              style={{
                display: "flex",
                height: "100%",
                flexDirection: "column",
                position: "relative",
              }}
            >
              <PdfViewerBottomToolbar
                isMobile={isMobile}
                hideTooltip={handleHideTooltipButton}
                signingDrawerWithoutImageStateHandler={
                  signingDrawerWithoutImageStateHandler
                }
                signWithoutImageModalHandler={showSignWithoutImageModal}
                signWithImageHandler={signingDrawerWithImageStateHandler}
                signWithImage={signWithImage}
                directSignHandler={directSignWithoutImageHandler}
                profileButtonChecked={checkedRadioButton}
                addSigImageInitial={addSigImageInitial}
                isSigAdded={addSignature}
                deleteSigImage={removeSignatureHandler}
                showRejectHandler={(rejectUrl: string) => rejectInfo(rejectUrl)}
                errorStatePdf={errorStatePdf}
                loadingState={loadingState}
                selectedProfile={selectedProfile}
              />
              <Spin
                style={{
                  marginTop: "250px",
                  width: "100%",
                  display: loadingState ? "flex" : "none",
                  justifyContent: "center",
                }}
                indicator={antIcon}
              />
              <div
                style={{
                  flex: 1,
                  overflow: "hidden",
                  visibility: loadingState ? "hidden" : "visible",
                }}
              >
                <Viewer
                  fileUrl={signatureParamsCtx.pdfFile ?? DefaultPdf}
                  plugins={[
                    defaultLayoutPluginInstance,
                    getFilePluginInstance as Plugin,
                  ]}
                  theme={{ theme: "light" }}
                  defaultScale={
                    isMobileDefaultZoomLevel ? SpecialZoomLevel.PageFit : 1.25
                  }
                  onPageChange={changePageHandler}
                  onDocumentLoad={handleDocumentLoad}
                  renderPage={renderPage}
                  renderError={renderError}
                  onZoom={zoom}
                ></Viewer>
              </div>
            </div>
          </Worker>
          <Modal
            visible={signWithoutImageModal.open}
            title={i18next.t("signature_image_title")}
            footer={null}
            onCancel={closeSignWithoutImageModal}
          >
            <div>
              <div
                style={{
                  fontSize: "15px",
                  fontWeight: "bold",
                  textAlign: "center",
                  fontFamily: "OpenSans",
                  marginBottom: "5px",
                }}
              >
                {i18next.t("no_sig_image_info_one")}
              </div>
              <div
                style={{
                  fontSize: "15px",
                  textAlign: "center",
                  fontFamily: "OpenSans",
                }}
              >
                {i18next.t("no_sig_image_info_two")}
                <b
                  style={{ color: "rgb(98, 61, 145)", cursor: "pointer" }}
                  onClick={() => {
                    closeSignWithoutImageModal();
                    signingDrawerWithImageStateHandler();
                  }}
                >
                  {i18next.t("no_sig_image_info_three")}
                </b>{" "}
                {i18next.t("no_sig_image_info_four")}
              </div>
              <div className={styles.Signator_SignWithoutImageModalButtons}>
                <div
                  className={styles.Signator_SignWithoutImageModalButtonOne}
                  onClick={() => {
                    if (signWithoutImageModal.rememberChoice === true) {
                      localStorage.setItem(
                        "eSignLiteSignatureImageWarning",
                        "true"
                      );
                    }
                    closeSignWithoutImageModal();
                    signingDrawerWithoutImageStateHandler();
                  }}
                >
                  {i18next.t("continue_without_img")}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <Checkbox
                  onChange={handleRememberChoiceCheckbox}
                  checked={signWithoutImageModal.rememberChoice}
                >
                  {i18next.t("dont_ask_again")}
                </Checkbox>
              </div>
            </div>
          </Modal>
          <IntroModal initialStep={1} />
          <FirstTimeVisitorModal
            closeModal={() => setFirstTimeVisitorModal(false)}
            firstName={signatureParamsCtx.firstName}
            lastName={signatureParamsCtx.lastName}
            showModal={firstTimeVisitorModal}
            phoneNumber={signatureParamsCtx.phoneNumber}
            jurisdiction={signatureParamsCtx.jurisdiction}
          />
        </>
      </div>
    </div>
  );
};

export default Signer;
