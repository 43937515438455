import styles from "./SignatureProfileComponent.module.css";
import i18next from "i18next";
import IDLogo from "../../images/potpis_karticom.svg";
import EuFlag from "../../images/EU.png";
import ChFlag from "../../images/CH.png";
import React from "react";

interface ISignatureProfileSettings {
  profile: any;
  smallFont?: boolean
}
export const SignatureProfileSettings = (props: ISignatureProfileSettings) => {
  return (
    <div className={styles.SignatorSignatureProfileSignatureDetails} style={{padding: props.smallFont ? "5px 2px" : "10px 4px"}}>
      {props.profile.txtSignatureLevel === "SES" && (
        <div
          style={{
            display: "flex",
            marginRight: "5px",
          }}
        >
          <span
            style={{
              fontSize: props.smallFont ? "8px" : "13px",
              fontWeight: "bold",
              color: "white",
              paddingLeft: "7px",
              paddingRight: "7px",
              borderRadius: "0.5rem",
              backgroundColor: "#b9b9b9",
            }}
            className={styles.Signator_SignatureProfileSignatureDetails_Text}
          >
            <span style={{ fontSize: props.smallFont ? "8px" : "12px" }}>{i18next.t("level")}: </span>
            {props.profile.txtSignatureLevel}
          </span>
        </div>
      )}
      {props.profile.txtSignatureLevel === "AES" && (
        <div
          style={{
            display: "flex",
            marginRight: "5px",
          }}
        >
          <span
            style={{
              fontSize: props.smallFont ? "8px" : "13px",
              fontWeight: "bold",
              color: "white",
              paddingLeft: "7px",
              paddingRight: "7px",
              borderRadius: "0.5rem",
              backgroundColor: "#1cbeda",
            }}
            className={styles.Signator_SignatureProfileSignatureDetails_Text}
          >
            <span style={{ fontSize: props.smallFont ? "8px" : "12px" }}>{i18next.t("level")}: </span>
            {props.profile.txtSignatureLevel}
          </span>
        </div>
      )}
      {props.profile.txtSignatureLevel === "QES" && (
        <div
          style={{
            display: "flex",
            marginRight: "5px",
          }}
        >
          <span
            style={{
              fontSize: props.smallFont ? "8px" : "13px",
              fontWeight: "bold",
              color: "white",
              paddingLeft: "7px",
              paddingRight: "7px",
              borderRadius: "0.5rem",
              backgroundColor: "#623d91",
            }}
            className={styles.Signator_SignatureProfileSignatureDetails_Text}
          >
            <span style={{ fontSize: props.smallFont ? "8px" : "12px" }}>{i18next.t("level")}: </span>
            {props.profile.txtSignatureLevel}
          </span>
        </div>
      )}
      {props.profile.txtSignatureProvider === "ExternalLocalComponent" && (
        <span
          style={{
            fontSize: props.smallFont ? "8px" : "13px",
            fontWeight: "bold",
            color: "#623d91",
          }}
          className={styles.Signator_SignatureProfileSignatureDetails_Text}
        >
          <div
            style={{
              fontSize: props.smallFont ? "8px" : "12px",
              color: "black",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <img src={IDLogo} height={25} width={25} />{" "}
            {i18next.t("smart_card")}
          </div>
        </span>
      )}
      {props.profile.txtSignatureProvider !== "ExternalLocalComponent" && (
        <span
          style={{
            fontSize: props.smallFont ? "8px" : "13px",
            fontWeight: "bold",
            color: "#623d91",
          }}
          className={styles.Signator_SignatureProfileSignatureDetails_Text}
        >
          <span style={{ fontSize: props.smallFont ? "8px" : "12px", color: "black" }}>
            {i18next.t("standard")}:{" "}
          </span>
          {props.profile.txtSignatureStandard === "EIDAS" && (
            <img
              src={EuFlag}
              style={{
                height: "18px",
                marginRight: "5px",
                marginLeft: "2px",
                marginBottom: "2px",
              }}
            />
          )}
          {props.profile.txtSignatureStandard === "ZERTES" && (
            <img
              src={ChFlag}
              style={{
                height: "18px",
                marginRight: "5px",
                marginLeft: "2px",
                marginBottom: "2px",
              }}
            />
          )}
          {props.profile.txtSignatureStandard}
        </span>
      )}
    </div>
  );
};