import styles from "./ProfilesSidebar.module.css";
import i18next from "i18next";
import { ChangeEvent } from "react";
import PenIcon from '../../images/pen_icon_white.svg'
import Kartica from '../../images/potpis_karticom.svg'
import Udaljeni from '../../images/udaljeni_potpis.svg'
import EuFlag from '../../images/EU.png'
import ChFlag from '../../images/CH.png'
import {SignatureProvider} from "../SigningComponent/SigningComponent";

interface IProfileCardNoImage {
  profile: any;
  selectedProfile: any;
  setSelectedProfile: any;
  checkedRadioButton: any;
  setCheckedRadioButton: any;
}
export const ProfileCardNoImage = (props: IProfileCardNoImage) => {

  const onRadioChange = (e: ChangeEvent<HTMLInputElement>) => {
    props.setCheckedRadioButton(e.target.value);
    props.setSelectedProfile(props.profile);
  };

  const handleOptionClick = () => {
    props.setCheckedRadioButton(props.profile.profileId);
    props.setSelectedProfile(props.profile);
  };

  return (
    <>
      <div className={styles.profileNoImageContainer}>
        {props.profile.txtSignatureProvider === "ExternalLocalComponent" ? (
          <div
            className={
              props.profile.profileId === props.checkedRadioButton
                ? styles.profileNoImageInformationChecked
                : styles.profileNoImageInformation
            }
            onClick={handleOptionClick}
          >
            <input
              type="radio"
              id={props.profile.profileId}
              value={props.profile.profileId}
              onChange={onRadioChange}
              checked={props.checkedRadioButton === props.profile.profileId}
              className={styles.customRadioButtonInput}
            />
            <div
              style={{
                width: "90%",
                textAlign: "center",
                paddingLeft: "15px",
                display: "flex",
                flexDirection: "column"
              }}
            >
              <img src={Kartica} height={30}/>
              {i18next.t("smart_card")}
            </div>
            <span className={styles.customRadioButtonCheckmark}></span>
          </div>
        ) : (
          <div
            className={
              props.profile.profileId === props.checkedRadioButton
                ? styles.profileNoImageInformationChecked
                : styles.profileNoImageInformation
            }
            onClick={handleOptionClick}
          >
            <input
              type="radio"
              id={props.profile.profileId}
              value={props.profile.profileId}
              onChange={onRadioChange}
              checked={props.checkedRadioButton === props.profile.profileId}
              className={styles.customRadioButtonInput}
            />
            <div
              style={{
                //borderRight: "1px solid #afafaf",
                display: "flex",
                width: "30%",
                justifyContent: "center",
                fontSize: "9px",
                height: "100%",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <img src={Udaljeni} height={30}/>
              {i18next.t("remote_sig")}
            </div>
            <div
              style={{
                //borderRight: "1px solid #afafaf",
                display: "flex",
                width: "23%",
                justifyContent: "center",
                fontSize: "9px",
                height: "100%",
                alignItems: "center",
                flexDirection: "column",
                gap: "5px"
              }}
              className={`NoImageProfileLevel${props.profile.txtSignatureLevel}`}
            >
              <img src={PenIcon} height={18} style={{paddingTop: "2px"}}/>
              <span style={{paddingTop: "4px"}}>{props.profile.txtSignatureLevel}</span>
            </div>
            <div
              style={{
                display: "flex",
                width: "36%",
                justifyContent: "center",
                fontSize: "9px",
                height: "100%",
                alignItems: "center",
                flexDirection: "column",
                gap: "5px"
              }}
            >
              <img style={{paddingTop: "2px"}} src={props.profile.txtSignatureStandard === "ZERTES" ? ChFlag : EuFlag} height={20}/>
              <span style={{paddingTop: "4px"}}>{props.profile.txtSignatureStandard}</span>
            </div>
            <span className={styles.customRadioButtonCheckmark}></span>
          </div>
        )}
      </div>
    </>
  );
};
